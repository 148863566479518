<template>
  <v-container flex >
    <v-row>
      <!-- <v-col col-6> -->
        <!-- <AddOneObjectModal :title="'איש קשר חדש'" :buttonLabel="'הוסף איש קשר חדש'" :addingFunction="'AddContactPerson'"
          :fieldsGetter="'contactPersonsNewFields'" :fieldsGetFunctionName="'contactPersonsNewFields'" /> -->
      <!-- </v-col> -->
      <v-col col-6>
        <v-btn outlined color="deep-orange darken-2" @click="addDialogNew = !addDialogNew" class="mx-2" dark>
          הוסף איש קשר חדש
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col xl="5" lg="5" md="12" sm="12" cols="12" v-for="(person, index) in contactPersons" :key="`${person.id}`">
        <ShowOneObject :id="person.id" :title="`איש קשר ${index + 1}`" :fieldsGetter="'contactPersonsFields'"
          :fieldsGetFunctionName="'contactPersonsFields'" :getFieldValuesFunc="'contactPerson'"
          :updateFieldValuesFunc="'updateContactPerson'" :removeFieldValuesFunc="'removeContactPerson'"
          :addFieldValuesFunc="'AddContactPerson'" />
      </v-col>
    </v-row>
    <v-dialog v-model="addDialogNew" max-width="500">
      <v-card>
        <v-card-title class="text-h5 deep-orange lighten-2 white--text">הוספת איש קשר חדש</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field v-model="newContactPerson.name" :label="'שם'" required></v-text-field>
            <v-text-field v-model="newContactPerson.email" :label="'אימייל'" required></v-text-field>
            <v-text-field v-model="newContactPerson.phone" :label="'טלפון'" required></v-text-field>
            <v-text-field v-model="newContactPerson.password" :label="'סיסמה'" required></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="addContactPerson">
            הוסף
          </v-btn>
          <v-btn color="error" @click="closeAddDialogNew()">
            ביטול
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import fire2 from "@/main2";
import fire from "@/main";
import { mapGetters } from "vuex";
import ShowOneObject from "@/components/app/MySpace/components/ShowOneObject";
// import AddOneObjectModal from "@/components/app/MySpace/components/AddOneObjectModal";
export default {
  name: "MainContactPersons",
  data: () => ({
    addDialog: false,
    addDialogNew: false,
    // contactPersons: null,
    newContactPerson: {
      name: "",
      email: "",
      phone: "",
      password: "",
    },
  }),
  methods: {
    async getContactPersons() {
      let viewMessage = this;
      await fire.database().ref(`users/${this.UID}/contactPersons`).on("value", async (snapshot) => {
        let info = snapshot.val();
        let info_arr = null;
        if (info) {
          console.log("info", info);
          info_arr = Object.keys(info).map(key => ({ ...info[key], id: key }))
          for (let i = 0; i < info_arr.length; i++) {
            if (info_arr[i].existUID && info_arr[i].existUID !== "") {
              fire.database().ref(`users/${info_arr[i].existUID}/info`).on("value", (snapshot) => {
                let existUSER = snapshot.val();
                console.log("existUSER", existUSER);
                info_arr[i] = {
                  ...info_arr[i], ...existUSER, role: existUSER.userRole, phone: existUSER.phoneNumber
                };
              });
            }
          }
          console.log("info_arr", info_arr);
          viewMessage.contactPersons = info_arr;
        }
      });
    },
    closeAddDialogNew() {
      this.addDialogNew = false;
      this.newContactPerson = {
        name: "",
        email: "",
        phone: "",
        password: "",
      };
    },
    async addContactPerson() {
      // let myEmail = this.user.email;
      // let myPassword = this.user.password;
      console.log("userCredential", this.userCredential);
      let userCredential = await fire2.auth().createUserWithEmailAndPassword(
        this.newContactPerson.email,
        this.newContactPerson.password
      );
      let userId = userCredential.user.uid;
      console.log(userId);
      await fire2.database().ref(`users/${userId}/info`).set({
        createdAt: Date(),
        uid: userId,
        email: this.newContactPerson.email,
        name: this.newContactPerson.name,
        userRole: "",
        bussinesNumber: "",
        secondEmail: "",
        phoneNumber: this.newContactPerson.phone,
        bussiness: false,
        showCars: false,
        showContactPersons: false,
        workerID: this.UID,
      });
      await fire2.database().ref(`users/${this.UID}/contactPersons/${userId}`).set({
        existUID: userId
      });
      this.closeAddDialogNew();
      // fire.auth().signInWithEmailAndPassword(this.userCredential.email, this.userCredential.password);
    },
  },
  components: {
    ShowOneObject,
    // AddOneObjectModal,
  },
  async created() {
    await this.$store.dispatch("loading", true);
    this.$store.dispatch('subscribeContactPersons');
    // await this.$store.dispatch("getContactPersons");
    // await this.getContactPersons();
    await this.$store.dispatch("loading", false);
    console.log("userCredential", this.userCredential);
  },
  beforeDestroy() {
    // Если подписались — желательно отписаться
    this.$store.dispatch('unsubscribeContactPersons');
  },
  computed: {
    ...mapGetters(["loading", "contactPersons", "UID", "user", "userCredential"]),
    // ...mapGetters(["loading", "UID", "user", "userCredential"]),
    contactPersonsComputed() {
      if (this.contactPersons) {
        return this.contactPersons;
      } else {
        return [];
      }
    },
  },
};
</script>
