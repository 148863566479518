import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCzliz8IA-YY7vBw0lm9Qc_d8HZ6V62d5g",
  authDomain: "go24-4b652.firebaseapp.com",
  databaseURL: "https://go24-4b652-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "go24-4b652",
  storageBucket: "go24-4b652.appspot.com",
  messagingSenderId: "260326599306", 
  appId: "1:260326599306:web:c2bae207c6271680315b73",
  measurementId: "G-ED0ECESK0S"

  // apiKey: "AIzaSyBe6eYg7PC3tbVF6YxgBVJ9T3q4MzrlDDc",
  // authDomain: "mythical-pod-582.firebaseapp.com",
  // databaseURL: "https://mythical-pod-582-default-rtdb.firebaseio.com",
  // projectId: "mythical-pod-582",
  // storageBucket: "mythical-pod-582.appspot.com",
  // messagingSenderId: "66874463517",
  // appId: "1:66874463517:web:909a31138e94f38d5b3df4",
  // measurementId: "G-4KXENXP6WT"
};

var fire2 = firebase.initializeApp(firebaseConfig, "fire2");



export default fire2;
