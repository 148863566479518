// store/modules/contactPersons.js

import firebase from 'firebase/compat/app';

export default {
  state: {
    contactPersons: [] // Храним массив, вместо null, чтобы было удобнее
  },

  mutations: {
    setContactPersons(state, payload) {
      state.contactPersons = payload || [];
    }
  },

  actions: {
    /**
     * 1) Подписаться на изменения в /contactPersons (Real-time).
     *    При любом изменении в БД (добавление, удаление, редактирование) 
     *    вызывается колбэк, в котором мы обновляем state.contactPersons.
     */
    async subscribeContactPersons({ commit, dispatch }) {
      const uid = await dispatch('getUID'); // получаем UID текущего пользователя

      // Навешиваем "слушатель" на ветку contactPersons
      firebase
        .database()
        .ref(`/users/${uid}/contactPersons`)
        .on('value', async (snapshot) => {
          const info = snapshot.val() || null;
          let infoArr = [];

          if (info) {
            // Преобразуем объект в массив
            infoArr = Object.keys(info).map((key) => ({
              ...info[key],
              id: key
            }));

            // Если нужно "подтягивать" доп.инфу о каждом existUID
            for (let i = 0; i < infoArr.length; i++) {
              const item = infoArr[i];
              if (item.existUID && item.existUID !== "") {
                // Загрузим /users/{existUID}/info
                const existUSER = await dispatch("getUserByID", { uid: item.existUID });
                // Сольём данные
                infoArr[i] = {
                  ...item,
                  ...existUSER,
                  role: existUSER.userRole,
                  phone: existUSER.phoneNumber
                };
              }
            }
          }

          // Коммитим обновлённый массив
          commit('setContactPersons', infoArr);
        });
    },

    /**
     * 2) Отписка от изменений в /contactPersons.
     *    Вызывайте при смене пользователя или при уничтожении компонента,
     *    если вам не нужна постоянная подписка.
     */
    async unsubscribeContactPersons({ dispatch }) {
      const uid = await dispatch('getUID');
      firebase
        .database()
        .ref(`/users/${uid}/contactPersons`)
        .off(); // снимаем все слушатели на этой ветке
    },

    /**
     * 3) Одноразовое чтение: получить список contactPersons "только один раз"
     *    (без реального времени).
     */
    async getContactPersons({ dispatch, commit }) {
      const uid = await dispatch('getUID');
      const snapshot = await firebase.database().ref(`/users/${uid}/contactPersons`).once('value');
      const info = snapshot.val() || null;

      let infoArr = null;
      if (info) {
        infoArr = Object.keys(info).map((key) => ({
          ...info[key],
          id: key
        }));

        for (let i = 0; i < infoArr.length; i++) {
          if (infoArr[i].existUID && infoArr[i].existUID !== "") {
            const existUSER = await dispatch("getUserByID", { uid: infoArr[i].existUID });
            infoArr[i] = {
              ...info[i],
              ...existUSER,
              role: existUSER.userRole,
              phone: existUSER.phoneNumber
            };
          }
        }
      }

      commit('setContactPersons', infoArr);
      return infoArr;
    },

    /**
     * Получение contactPersons для "другого" uid
     */
    async getContactPersonsByID(_, payload) {
      // payload - это uid другого пользователя
      const snapshot = await firebase.database()
        .ref(`/users/${payload}/contactPersons`)
        .once('value');
      return snapshot.val();
    },

    /**
     * Получение одной contactPerson из /users/{uid}/contactPersons/{id}
     */
    async getContactPersonByUserByID(_, payload) {
      const snapshot = await firebase.database()
        .ref(`/users/${payload.uid}/contactPersons/${payload.id}`)
        .once('value');
      return snapshot.val();
    },

    /**
     * Обновить данные contactPerson
     */
    async updateContactPerson({ dispatch }, payload) {
      const uid = await dispatch('getUID');
      await firebase.database()
        .ref(`users/${uid}/contactPersons`)
        .child(payload.id)
        .update({
          name: payload.name || '',
          role: payload.role || '',
          phone: payload.phone || '',
          email: payload.email || '',
          existUID: payload.existUID || ''
        });

      // Если используете однократный getContactPersons — обновим локальный state
      // Или полагаетесь на subscribeContactPersons, тогда это не обязательно:
      await dispatch('getContactPersons');
    },

    /**
     * Добавить контакт.
     * Если existUID нет, то просто пушим поля в contactPersons текущего юзера.
     * Иначе — пушим existUID и проставляем связь workerOf в инфо второго юзера
     */
    async AddContactPerson({ dispatch }, payload) {
      const uid = await dispatch('getUID');

      if (!payload.existUID || payload.existUID === "") {
        // Создаём "чистый" контакт
        await firebase.database().ref(`users/${uid}/contactPersons`).push({
          name: payload.name || '',
          role: payload.role || '',
          phone: payload.phone || '',
          email: payload.email || ''
        });
      } else {
        // Привязываем существующего юзера
        await firebase.database().ref(`users/${uid}/contactPersons`).push({
          existUID: payload.existUID || ''
        });
        // Запишем, кто его "работодатель" (например)
        await firebase.database().ref(`users/${payload.existUID}/info`).update({
          workerOf: uid
        });
      }

      // Если не используете подписку, то обновим state
      await dispatch('getContactPersons');
    },

    /**
     * Удалить контакт
     */
    async removeContactPerson({ dispatch }, payload) {
      const uid = await dispatch('getUID');
      await firebase.database().ref(`users/${uid}/contactPersons/${payload}`).remove();

      // Обновляем state
      await dispatch('getContactPersons');
    },

    /**
     * Вернуть уже сохранённый в state список
     * (если нужно без перезапроса)
     */
    refreshContactPersons({ getters }) {
      return getters.contactPersons;
    },

    /**
     * Получить конкретный контакт из state по id
     */
    contactPerson({ getters }, payload) {
      const all = getters.contactPersons;
      if (!all) return null;
      return all.find(c => c.id === payload) || null;
    },

    /**
     * Пример вспомогательного экшена (если нужно):
     * Загрузка /users/{uid}/info (который вы вызываете изнутри)
     */
    // async getUserByID(_, { uid }) {
    //   const snapshot = await firebase.database().ref(`/users/${uid}/info`).once('value');
    //   return snapshot.val() || {};
    // }
  },

  getters: {
    contactPersons(state) {
      return state.contactPersons || [];
    }
  }
};
